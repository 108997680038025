import React, { useContext } from 'react';
import GoBackLink from '../components/go-back-link';
import Meta from '../components/meta';
import LokaltogContext from '../context/context';

const NotFoundPage = () => {
	const context = useContext(LokaltogContext);

	return (
		<>
			<Meta {...{title: '404'}} />
			<div className='box-wide not-found-page'>
				<div className='not-found-page__content'>
					<h1>
						<div>404</div>
						<div>{context.tr('404.Headline')}</div>
					</h1>
					<p className='not-found-page__description'>{context.tr('404.Description')}</p>
					<GoBackLink parent={{url:'/', name:context.tr('404.Frontpage')}}></GoBackLink>
				</div>
			</div>
		</>
	);
};

export default NotFoundPage;
